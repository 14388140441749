import {HTTP} from '../utils/axios'

class UserUploadRecordModel extends HTTP {
  // 列表
  index(data) {
      return this.request({
          url: 'board/user_upload_records',
          method: "GET",
          data: data
      })
  }

  /*增加*/
  create(data) {
    return this.request({
        url: "board/user_upload_records",
        method: "POST",
        data: data,
    })
  }

  /*修改*/
  update(data, id) {
    return this.request({
        url: "board/user_upload_records/" + id,
        method: "PATCH",
        data: data,
    })
  }

  // 表单
  show(id) {
    return this.request({
        url: 'board/user_upload_records/' + id,
        method: "GET"
    })
  }

  // 获取用户上传文档 pdf 链接
  getDocPreviewFileUrl(id) {
    return this.request({
      url: `board/user_upload_records/${id}/preview_file_url`,
      method: "GET"
    })
  }
}

export { UserUploadRecordModel }